.container {
  width: 100%;
}

.ticketContainer {
  width: 100%;
}

.ticketContainer img {
  max-width: 100%;
}

.headerContainer {
  /* position: relative; */
  width: 100%;
  /* margin: 1rem auto; */
  /* border-radius: 5px; */
  overflow: hidden;
}

.seeMoreButtonContainer {
  /* position: absolute; */
  /* bottom: 0; */
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  padding: 0;
}

.seeMoreButton {
  border-width: 0;
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0 auto;
  background-color: white;
  font-weight: bold;
  color: var(--primary);
  font-size: 0.8rem;
  width: 9rem;
  text-decoration: none;
  text-align: center;
}

.ticketFormContainer {
  background-color: white;
  margin: 2rem 0.5rem;
}

.navigationContainer {
  display: flex;
  justify-content: center;
  border: 1px solid var(--primary);
  border-radius: 5px;
}

.ticketFormContainer form {
  padding: 1rem;
}

.navigationButton {
  width: 50%;
  padding: 0.3rem 1rem;
  margin: 0.2rem;
  background-color: white;
  border-radius: 5px;
  border-width: 0;
  font-weight: bold;
  color: var(--primary);
}

.ticketButton {
  background-color: var(--primary);
  color: var(--white);
}

.tutorButton {
  background-color: var(--primary);
  color: var(--white);
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formLabel {
  margin: 1rem 0 0.7rem;
}

.formInput {
  padding: 0.5rem;
}

.formUploadButton {
  border: 1px solid var(--primary);
  border-radius: 5px;
  text-align: center;
  width: fit-content;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.selectContainer {
  display: flex;
  align-items: center;
}

.selectContainer p {
  margin: 0.5rem 0;
  padding-left: 1rem;
  cursor: pointer;
}

.formFilename {
  margin: 1rem 0 0;
}

.formFeedbackInput {
  height: 5rem;
  padding: 0.5rem;
}

.formSubmitButton {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--primary);
  border-width: 0;
  border-radius: 5px;
  color: var(--white);
  font-weight: bold;
  text-align: center;
}

.searchContainer {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

.searchTel {
  /* width: 50%; */
  text-align: center;
  padding: 0.4rem 0;
  border: 1px solid var(--primary);
  border-radius: 5px;
  align-self: center;
}

.searchButton {
  /* width: 20%; */
  border-width: 0;
  background-color: #ccc;
  padding: 0.2rem 1rem;
  margin: 0 1rem;
}

.qrContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
