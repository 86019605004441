.drivingRegisterContainer {
}

.drivingFormContainer {
  border-radius: 1rem;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formLabel {
  margin: 1rem 0 0.7rem;
}

.formInput {
  padding: 0.5rem;
}

.formUploadButton {
  border: 1px solid var(--primary);
  border-radius: 5px;
  text-align: center;
  width: fit-content;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.selectContainer {
  display: flex;
  align-items: center;
}

.selectContainer p {
  margin: 0.5rem 0;
  padding-left: 1rem;
  cursor: pointer;
}

.formFeedbackInput {
  height: 5rem;
  padding: 0.5rem;
}

.formSubmitButton {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--primary);
  border-width: 0;
  border-radius: 5px;
  color: var(--white);
  font-weight: bold;
  text-align: center;
}

.orderContainer {
  width: 95%;
  margin: 0.5rem auto;
  padding: 0.5rem;
  background-color: var(--white);
  border-radius: 5px;
}

.orderContainer p {
  margin: 0.2rem 0;
  font-size: 0.9rem;
}
